.game--page {
    background-color: white;
}

.game--content {
    display: flex;
}

.game--filters {
    flex: 1;
    padding-top: 20px;
    background-color: white;
    width: 20%;
    margin: 20px;
    border: solid 1px #BDBDBD;
    border-radius: 10px;
    max-height:  450px;
}
.game--filters--header{
    background-color: #9E9E9E;
    margin-top: -41px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.game--filters--header h1{
    padding-bottom: 20px;
    padding-top: 10px;
    padding-left: 25px;
    
}



.game--list {
    flex: 4;
    background-color: white;
    width: 80%;
    margin: 20px;
    padding-top: 20px;
    border: solid 1px #BDBDBD;
    border-radius: 10px;
}

.game--list--header {
    display: flex;
    align-items: center;
    background-color: #9E9E9E;
    height: 80px;
    margin-top: -21px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    justify-content: space-between;
}
.game--list--header h1 {
    padding-left: 25px
}


.game--footer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #9E9E9E; */
    height: 60px;
    font-style: italic;
    margin-top: 20px;   
    /* move the footer to the buttom of the page */
    position: fixed;
    bottom: 0;
    width: 100%;
}
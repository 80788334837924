.unauthenticated--div {
    /* show everything in the center */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* vertically center */
    height: 80vh;
}

.create-game{
    text-align: center;
    margin: auto;
    padding-top: 70px;
    
}


.form--create-game{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 60%;
    
    gap: 15px;
    border: 1px solid #9E9E9E;
    border-radius: 5px;
    background-color: transparent;
    box-shadow: 0px 0px 8px #ccc;

    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    height: 300px;
}
.form--create-game:hover {  
    border: solid 1px black; 
}

.form--create-game--column{
    display: grid;
    text-align: left;
    padding-left: 20%;
    
    
}

.input--create-game {
    width: 85%;
    box-sizing: border-box;
    
    
    font-size: 14px;
    text-align: center;
    height: 30px;
    
    border-radius: 5px;
    box-shadow: 0px 0px 8px #ccc;
    border: none;
} .input--create-game:hover {
    border: solid 1px #9E9E9E; 
} .input--create-game:focus {
    border: solid 1px #9E9E9E; 
    outline: none;
}.input--create-game:active {
    border: solid 1px #9E9E9E; 
}

.button--create-game {
    padding-bottom: 5px 5px 5px 5px;
    font-size: 14px;
    height: 30px;
    width: 110px;
    margin-top: 20px;
    
    border-radius: 10px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0px 0px 8px #ccc; 
    background-color: white;
}
.button--create-game:hover{
    border: solid 1px #9E9E9E;
}
.button--create-game:focus{
    outline: none;
}
.button--create-game:active{
    background-color: #3e8e41;
}
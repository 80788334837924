.navbar--div {
    background-color: #D32F2F;
    color: #212121;
    height: 70px;
    display: flex;
  }
  
  .navbar--title {
    font-size: 20px;
    font-weight: bold;
    padding-top: 5px;
    padding-left: 5px;
    text-decoration: none;
    color:#212121;
    position: absolute;
    top: 0px;
    white-space: nowrap;
  }
  
  .navbar--menu {
    list-style-type: none;
    display: flex;
    justify-content:center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .navbar--menu li {
    margin-right: 10px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .navbar--menu li a {
    color: #212121;
    text-decoration: none;
    padding: 5px 10px;
  }
  
  .navbar--menu li a:hover {
    color: #FFFFFF;
  }
  
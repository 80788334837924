.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    position: relative;
    padding: 32px   ;
    width: 100%;
    max-width: 640px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.popup-inner .close-btn {
    background-color: transparent;
    border-radius: 10px;
    height: 2rem;
    padding: 0 15px;
    box-shadow: 0px 0px 8px #ccc;
    display: flex;
    align-items: center;
    margin-top: 1%;
    border: none;
    cursor: pointer;
    text-decoration: none;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    width: 10%;
} .close-btn:hover {
    border: solid 1px #9E9E9E; 
}
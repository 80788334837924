.team--header {
  display: flex;
  justify-content: space-between;
}

.search-team {
  background-color: white;
  width: 40%;
  border-radius: 10px;
  height: 2.5rem;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ccc;
  display: flex;
  align-items: center;
  margin-top: 1%;
  margin-left: 1.3%;
}

.search-team:hover {
  border: solid 1px #9E9E9E;
}

.search-input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1.25rem;
  margin-left: 5px;
}

.search-input:focus {
  outline: none;
}

.search-icon {
  color: #D32F2F;
  font-size: 1.25rem;
}

.create-team--btn {
  background-color: transparent;
  border-radius: 10px;
  height: 2.5rem;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ccc;
  display: flex;
  align-items: center;
  margin-top: 1%;
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin-right: -15px;
}

.create-team--btn:hover {
  border: solid 1px #9E9E9E;
}

.div--public-teams, .div--private-teams {
  display: flex;
  overflow-x: scroll;
}

.li--list-public-teams, .li--list-private-teams {
  list-style: none;
  width: 25vh;
  height: 25vh;
  border-radius: 5px;
  border: solid 0.5px #BDBDBD;
  box-shadow: 0 0 5px #ccc;
  gap: 1rem;
  position: relative;
}

.li--list-public-teams:hover, .li--list-private-teams:hover {
  border: solid 3px #9E9E9E;
}

.div--team-name {
  font-size: 1rem;
  font-weight: bold;
  margin-left: 5%;
  margin-top: 5%;
}

.div--team-join {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.btn--join-team {
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 0px 8px #ccc;
  background-color: transparent;
  border-radius: 5px;
  height: 1.5rem;
  margin: 0 auto 5px 25px;
  align-items: center;
  justify-content: center;
}

.btn--join-team:hover {
  border: solid 1px #9E9E9E;
}

.div--team-players {
  font-style: italic;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
}

/*# sourceMappingURL=TeamsList.css.map */